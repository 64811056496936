import React from "react";
import PizzaBoxComponent from "./PizzaBoxComponent";
import FoodCategory from "./FoodCategory";
import parse from "html-react-parser";
const BodyComponent = () => {
  const doners = [
    {
      name: "NEUBAU",
      ingredients:
        "Sie haben ein Grundstück und einen Bauplan parat, jedoch noch kein Bauunternehmen gefunden, das Ihren Neubautraum realisiert? Zögern Sie nicht, sich an ein erfahrenes Team zu wenden, das bereits zahlreiche Projekte in ganz Österreich, insbesondere im Bundesland Steiermark, erfolgreich umgesetzt hat.",
    },
    {
      name: "RENOVIERUNG",
      ingredients:
        "Sie haben eine bestehende Immobilie und träumen von einer umfassenden Renovierung? Suchen Sie nicht weiter! Unser erfahrenes Team steht bereit, Ihre Renovierungswünsche mit Fachkenntnissen und Hingabe umzusetzen. Wir haben bereits zahlreiche erfolgreiche Renovierungsprojekte in ganz Österreich, besonders in der Steiermark, realisiert. Vertrauen Sie uns, um Ihre bestehende Immobilie in einen modernen und funktionalen Raum zu verwandeln",
    },
    {
      name: "SANIERUNG",
      ingredients:
        "Sie stehen vor der Herausforderung einer umfassenden Sanierung Ihres Gebäudes? Wir sind Ihr verlässlicher Partner! Unser erfahrenes Team steht Ihnen mit Kompetenz und Leidenschaft zur Seite, um Sanierungsprojekte erfolgreich umzusetzen. Mit zahlreichen erfolgreichen Sanierungen in ganz Österreich, insbesondere in der Steiermark, haben wir bewiesen, dass wir die richtige Wahl für die Erhaltung und Verbesserung Ihrer Bausubstanz sind. Vertrauen Sie uns, um Ihr Gebäude in einen zeitgemäßen und effizienten Zustand zu versetzen.",
    },
  ];
  const categorydonerList = { name: "LEISTUNGEN" };

  const donerList = doners.map((food) => <PizzaBoxComponent food={food} />);
  return (
    <div class="container-fluid p-0 ">
      <br />
      <p style={{ textAlign: "center", fontSize: 30, fontWeight: "bold" }}>
        Wir sind jung und haben den brennenden Wunsch, Ihre Traumimmobilie gemeinsam mit Ihnen zu realisieren, damit Sie auch morgen noch staunend sagen können, "das ist genau mein Traum!!"
      </p>
      <FoodCategory category={categorydonerList} />
      {donerList}
    </div >
  );
};
export default BodyComponent;
